import axios from 'axios';
import { getI18n } from 'react-i18next';
import { API_BASE_URL } from './config';

const getConfig = (accessToken) => {
  const i18n = getI18n();

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
    params: {
      locale: i18n.language,
    },
  };
};

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  withCredentials: true,
  // timeout: 50000,
});

export async function login(username, password) {
  return await axiosInstance.post(`/login`, { id: username, password });
}

export async function passwordReset(username, email) {
  return await axiosInstance.post(`/password-reset`, { id: username, email });
}

export async function getUserAccount(accessToken) {
  return await axiosInstance.get(`/user/account`, getConfig(accessToken));
}

export async function updateUserAccount(accessToken, password, passwordNew) {
  return await axiosInstance.put(
    `/user/account`,
    {
      password,
      password_new: passwordNew,
      password_new_confirmation: passwordNew,
    },
    getConfig(accessToken)
  );
}

export async function getFavoriteMaterials(accessToken) {
  return await axiosInstance.get(`/materials/favorites`, getConfig(accessToken));
}

export async function getAllMaterials(accessToken) {
  return await axiosInstance.get(`/materials/all`, getConfig(accessToken));
}

export async function getOrders(accessToken, perPage = 20, page = 1, filterParams = {}) {
  const params = Object.keys(filterParams).map((key) => `filters[${key}]=${filterParams[key]}`);

  params.push(`sort=created_at`, `per_page=${perPage}`, `page=${page}`);

  return await axiosInstance.get(`/orders?${params.join('&')}`, getConfig(accessToken));
}

export async function getOrder(accessToken, orderId) {
  return await axiosInstance.get(`/orders/${orderId}`, getConfig(accessToken));
}

export async function createOrder(accessToken, orderData) {
  const { orderItems, pickupDate, deliveryDate, boxCount, comment } = orderData;

  return await axiosInstance.post(
    `/orders`,
    {
      materials: orderItems,
      when_to_pick_up: pickupDate,
      when_to_deliver: deliveryDate,
      box_count: boxCount,
      comment,
    },
    getConfig(accessToken)
  );
}

export async function updateOrder(accessToken, orderId, orderData) {
  const { orderItems, pickupDate, deliveryDate, boxCount, comment } = orderData;

  return await axiosInstance.put(
    `/orders/${orderId}`,
    {
      materials: orderItems,
      when_to_pick_up: pickupDate,
      when_to_deliver: deliveryDate,
      box_count: boxCount,
      comment,
    },
    getConfig(accessToken)
  );
}

export async function downloadFile(accessToken, fileId, hash) {
  const config = getConfig(accessToken);

  return await axiosInstance.get(`/files/${fileId}/download/${hash}`, {
    ...config,
    responseType: 'blob',
  });
}

/**
 * Account Manager endpoints
 */
export async function accountManagerLogin(email, password) {
  return await axiosInstance.post(`/account-managers/login`, {
    email,
    password,
  });
}

export async function accountManagerPasswordReset(email) {
  return await axiosInstance.post(`/account-managers/password-reset`, { email });
}

export async function getAccountManager(accessToken, accountManagerId) {
  return await axiosInstance.get(`/account-managers/${accountManagerId}`, getConfig(accessToken));
}

export async function getAccountManagerUserAccount(accessToken) {
  return await axiosInstance.get(`/account-managers/user/account`, getConfig(accessToken));
}

export async function updateAccountManagerUserAccount(accessToken, password, passwordNew) {
  return await axiosInstance.put(
    `/account-managers/user/account`,
    {
      password,
      password_new: passwordNew,
      password_new_confirmation: passwordNew,
    },
    getConfig(accessToken)
  );
}

export async function getUsers(accessToken, accountManagerId) {
  const config = getConfig(accessToken);

  if (accountManagerId) {
    config.params = {
      ...config.params,
      account_manager_id: accountManagerId,
    };
    config.params.account_manager_id = accountManagerId;
  }

  return await axiosInstance.get(`/account-managers/users`, config);
}

export async function getUser(accessToken, userId) {
  return await axiosInstance.get(`/account-managers/users/${userId}`, getConfig(accessToken));
}

export async function getUserOrders(
  accessToken,
  userId,
  perPage = 20,
  page = 1,
  filterParams = {}
) {
  const params = Object.keys(filterParams).map((key) => `filters[${key}]=${filterParams[key]}`);

  params.push(`sort=created_at`, `per_page=${perPage}`, `page=${page}`);

  return await axiosInstance.get(
    `/account-managers/users/${userId}/orders?${params.join('&')}`,
    getConfig(accessToken)
  );
}

export async function resetUserPassword(accessToken, userId, email, sendEmail) {
  return await axiosInstance.post(
    `/account-managers/users/${userId}/password-reset`,
    {
      email,
      send_email: sendEmail,
    },
    getConfig(accessToken)
  );
}

export async function getAccountManagers(accessToken) {
  return await axiosInstance.get(`/account-managers`, getConfig(accessToken));
}

export async function getAccountManagerUsers(accessToken, accountManagerId) {
  return await axiosInstance.get(
    `/account-managers/${accountManagerId}/users`,
    getConfig(accessToken)
  );
}

export async function resetAccountManagerPassword(accessToken, accountManagerId, email, sendEmail) {
  return await axiosInstance.post(
    `/account-managers/${accountManagerId}/password-reset`,
    {
      email,
      send_email: sendEmail,
    },
    getConfig(accessToken)
  );
}
