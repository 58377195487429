import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Loader from '../util/Loader';
import {
  getUserAccount,
  updateUserAccount,
  getAccountManagerUserAccount,
  updateAccountManagerUserAccount,
} from '../../api';

const UserAccount = ({ accessToken, isAccountManager }) => {
  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getUserData();

        if (res) {
          if (isAccountManager) {
            setUsername(res.data.email);
          } else {
            setUsername(res.data.id);
          }
        } else {
          console.error('Error fetching user data');

          setError('user_fetch_error');
        }
      } catch (err) {
        console.error('Fetch user error', err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [accessToken]);

  const getUserData = async () => {
    if (isAccountManager) {
      return await getAccountManagerUserAccount(accessToken);
    }

    return await getUserAccount(accessToken);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordNew !== passwordCheck) {
      setError('passwords_dont_match');

      return;
    }

    try {
      setIsLoading(true);

      if (isAccountManager) {
        await updateAccountManagerUserAccount(accessToken, password, passwordNew);
      } else {
        await updateUserAccount(accessToken, password, passwordNew);
      }
    } catch (err) {
      console.error(err);

      setIsLoading(false);
      setError(err.response.message);
    } finally {
      navigate('/user/account');

      setIsLoading(false);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordNewChange = (event) => {
    setPasswordNew(event.target.value);
  };

  const handlePasswordCheckChange = (event) => {
    setPasswordCheck(event.target.value);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('my_account.my_account')}
        </Typography>
        {error && <Alert severity="error">{t(error)}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            name="username"
            label={t('my_account.username')}
            id="username"
            value={username}
            disabled={true}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('my_account.old_password')}
            type="password"
            id="password"
            value={password}
            autoComplete="current-password"
            onChange={handlePasswordChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password_new"
            label={t('my_account.new_password')}
            type="password"
            id="password-new"
            value={passwordNew}
            error={error !== null}
            helperText={error !== null ? error : ''}
            autoComplete="new-password"
            onChange={handlePasswordNewChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password_check"
            label={t('my_account.confirm_password')}
            type="password"
            id="password-check"
            value={passwordCheck}
            error={error !== null}
            helperText={error !== null ? error : ''}
            autoComplete="check-password"
            onChange={handlePasswordCheckChange}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('my_account.save')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserAccount;
