import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import ResponsiveAppBar from './ResponsiveAppBar';
import Router from '../../routes';
import { useAuth } from '../../hooks/useAuth';
import bgImage from '../../assets/images/bg.webp';

export default function Layout() {
  const { user } = useAuth();

  const defaultStyle = {
    flexGrow: 1,
    // p: 3,
  };

  const mainStyle = !user
    ? {
        ...defaultStyle,
        backgroundImage: `url('${bgImage}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }
    : defaultStyle;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box component="main" sx={mainStyle}>
        <ResponsiveAppBar />
        <Container>
          <Router />
        </Container>
      </Box>
    </Box>
  );
}
