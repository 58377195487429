import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

const QuantityBox = ({
  initialValue,
  minimumValue,
  maximumValue,
  id,
  labelId,
  onChange,
  disabled = false,
  isFocus = false,
}) => {
  const [quantity, setQuantity] = useState(initialValue || 0);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const defaultMaximumValue = maximumValue || 999;

  const handleIncrement = (event) => {
    event.preventDefault();

    setError(null);

    if (quantity + 1 > defaultMaximumValue) {
      setError(
        t('components.quantity_box.errors.value_exceeds_maximum_limit') + ' ' + defaultMaximumValue
      );

      return;
    }

    updateQuantity(quantity + 1);
  };

  const handleDecrement = (event) => {
    event.preventDefault();

    setError(null);

    if (quantity > minimumValue) {
      updateQuantity(quantity - 1);
    }
  };

  const handleChange = (event) => {
    event.preventDefault();

    setError(null);

    const regex = /^[0-9\b]+$/;
    const valueInt = parseInt(event.target.value);

    if (event.target.value > defaultMaximumValue) {
      setError(
        t('components.quantity_box.errors.value_exceeds_maximum_limit') + ' ' + defaultMaximumValue
      );

      return;
    }

    if (
      regex.test(event.target.value) &&
      valueInt >= minimumValue &&
      valueInt <= defaultMaximumValue
    ) {
      updateQuantity(valueInt);
    }
  };

  const updateQuantity = (value) => {
    setQuantity(value);

    onChange(value);
  };

  return (
    <>
      <ButtonGroup
        aria-labelledby={labelId}
        variant="contained"
        size="small"
        aria-label="small outlined button group"
        key={id}
      >
        <Button onClick={handleDecrement} sx={{ primary: 'primary' }}>
          -
        </Button>
        <TextField
          type="number"
          onChange={handleChange}
          value={quantity}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          size="small"
          sx={{
            width: { xs: '50px', sm: '50px', md: '80px', lg: '80px' },
            textAlign: 'right',
          }}
          disabled={disabled}
          autoFocus={isFocus}
          focused={isFocus}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'inherit', lg: 'inherit' },
                }}
              >
                {t('components.quantity_box.pieces')}
              </InputAdornment>
            ),
          }}
          error={error !== null}
        />
        <Button onClick={handleIncrement}>+</Button>
      </ButtonGroup>
      {error && (
        <Typography component="div" sx={{ color: '#d32f2f', fontSize: 'smaller', marginTop: 1 }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default QuantityBox;
