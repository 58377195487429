import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray, Field } from 'formik';
import dayjs from 'dayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import EmptyTextarea from '../util/EmptyTextarea';
import MaterialList from '../MaterialList';
import QuantityBox from '../util/QuantityBox';

const OrderFormikForm = ({
  initialValues,
  handleSubmit,
  accessToken,
  allMaterials,
  defaultPickupDate,
  defaultDeliveryDate,
  isEditMode,
  isPartiallyEditable,
  success,
  error,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { t, i18n } = useTranslation();

  const today = dayjs();
  const isDateSunday = (date) => date.day() === 0;
  // const isDateSunday = (date) => date.getDay() === 0;

  const gridRowStyle = {
    p: 2,
    borderRight: '1px solid rgb(224, 224, 224);',
    borderBottom: '1px solid rgb(224, 224, 224);',
    borderLeft: '1px solid rgb(224, 224, 224);',
  };

  const setFieldError = (error) => {
    let message = null;

    if (error === 'minDate') {
      message = 'Datum dodání musí být o více než jeden den po datu vyzvednutí';
    }

    setErrorMessage(message);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        orderItems: initialValues.orderItems || [],
        pickupDate: initialValues.pickupDate || defaultPickupDate,
        deliveryDate: initialValues.deliveryDate || defaultDeliveryDate,
        boxCount: initialValues.boxCount || 1,
        comment: initialValues.comment || '',
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);

        if (!errorMessage) {
          handleSubmit(values);

          resetForm();
        }
      }}
    >
      {(formik) => (
        <Form>
          <Container component="main" maxWidth="lg" sx={{ padding: { xs: 0, sm: 0 } }}>
            <Box
              sx={{
                marginTop: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                sx={{ fontWeight: 'bold', marginTop: 3, marginBottom: 2 }}
              >
                {isEditMode ? t('order_form.edit_order') : t('order_form.new_order')}
              </Typography>
              {success && (
                <Alert severity="success">
                  {isEditMode
                    ? t('order_form.order_update_success')
                    : t('order_form.order_store_success')}
                </Alert>
              )}
              {error && <Alert severity="error">{t(error)}</Alert>}
              {!success && (
                <Box sx={{ width: { xs: '100%', sm: '100%', md: '90%', lg: '60%' } }}>
                  <FormControl sx={{ width: '100%' }}>
                    <FieldArray
                      name="orderItems"
                      render={(arrayHelpers) => (
                        <MaterialList
                          accessToken={accessToken}
                          arrayHelpers={arrayHelpers}
                          orderItems={formik.values.orderItems}
                          allMaterials={allMaterials}
                          gridRowStyle={gridRowStyle}
                          isEditMode={isEditMode}
                        />
                      )}
                    />
                    <Field name="pickupDate">
                      {() => (
                        <Grid container spacing={0} sx={gridRowStyle}>
                          <Grid item xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={i18n.language || 'cs'}
                            >
                              <DatePicker
                                label={t('order_form.when_to_pick_up')}
                                id="pickupDate"
                                type="date"
                                value={formik.values.pickupDate}
                                onChange={(value) => formik.setFieldValue('pickupDate', value)}
                                inputFormat="DD.MM.YYYY"
                                views={['year', 'month', 'day']}
                                size="small"
                                disablePast={true}
                                minDate={today}
                                disabled={!isPartiallyEditable}
                                shouldDisableDate={isDateSunday}
                                required
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                    <Field name="deliveryDate">
                      {() => (
                        <Grid container spacing={0} sx={gridRowStyle}>
                          <Grid item xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={i18n.language || 'cs'}
                            >
                              <DatePicker
                                label={t('order_form.when_to_deliver')}
                                id="deliveryDate"
                                type="date"
                                value={formik.values.deliveryDate}
                                onChange={(value) => formik.setFieldValue('deliveryDate', value)}
                                inputFormat="DD.MM.YYYY"
                                views={['year', 'month', 'day']}
                                size="small"
                                onError={(newError) => setFieldError(newError)}
                                slotProps={{
                                  textField: {
                                    helperText: errorMessage,
                                  },
                                }}
                                disablePast={true}
                                minDate={formik.values.pickupDate.add(2, 'day')}
                                disabled={!isPartiallyEditable}
                                shouldDisableDate={isDateSunday}
                                required
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                    <Field name="boxCount">
                      {() => (
                        <Grid container spacing={0} sx={gridRowStyle}>
                          <Grid item xs={4}>
                            <label htmlFor="boxCount" id="boxCountLabel">
                              {t('order_form.box_count')}:{' '}
                            </label>
                          </Grid>
                          <Grid item xs={8}>
                            <QuantityBox
                              label={t('order_form.box_count')}
                              id="boxCount"
                              labelId="boxCountLabel"
                              initialValue={formik.values.boxCount}
                              minimumValue={1}
                              onChange={(value) => formik.setFieldValue('boxCount', value)}
                              disabled={!isPartiallyEditable}
                              required
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                    <Field name="comment">
                      {() => (
                        <Grid container spacing={0} sx={gridRowStyle}>
                          <Grid item xs={12}>
                            <EmptyTextarea
                              id="comment"
                              defaultValue={formik.values.comment}
                              onChange={(value) => formik.setFieldValue('comment', value)}
                              placeholder={t('order_form.order_note')}
                              minRows={3}
                              sx={{ width: '100%' }}
                              maxLength={250}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                    <Grid container spacing={0} sx={gridRowStyle}>
                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button variant="contained" color="success" type="submit">
                          {isEditMode ? t('order_form.update_order') : t('order_form.submit_order')}
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Box>
              )}
            </Box>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default OrderFormikForm;
