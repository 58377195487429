import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import OrderFormikForm from './OrderFormikForm';
import Loader from '../util/Loader';
import {
  getAllMaterials,
  getFavoriteMaterials,
  getOrder,
  createOrder,
  updateOrder,
} from '../../api';
import { scrollToTop } from '../../helpers';

const defaultPickupDate = dayjs().add(1, 'day');
const defaultDeliveryDate = defaultPickupDate.add(2, 'day');

const isDateSunday = (date) => date.day() === 0;

const FORM_INITIAL_VALUES = {
  orderItems: [],
  pickupDate: isDateSunday(defaultPickupDate) ? defaultPickupDate.add(1, 'day') : defaultPickupDate,
  deliveryDate: isDateSunday(defaultDeliveryDate)
    ? defaultDeliveryDate.add(1, 'day')
    : defaultDeliveryDate,
  boxCount: 1,
  comment: '',
};

const OrderFormHandler = ({ accessToken }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allMaterials, setAllMaterials] = useState([]);
  const [favoriteMaterials, setFavoriteMaterials] = useState([]);
  const [isMaterialLoading, setIsMaterialLoading] = useState(true);
  const [isFavoritesLoading, setIsFavoritesLoading] = useState(true);
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  const [isPartiallyEditable, setIsPartiallyEditable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState(FORM_INITIAL_VALUES);

  const { uuid: orderId } = useParams();
  const navigate = useNavigate();

  const isLoading = isFavoritesLoading || isMaterialLoading || isOrderLoading || isSubmitting;

  const formatData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        isFavorite: false,
        quantity: 1,
      };
    });
  };

  useEffect(() => {
    const fetchAllMaterials = async () => {
      try {
        setIsMaterialLoading(true);

        const res = await getAllMaterials(accessToken);

        setAllMaterials(formatData(res.data));
      } catch (err) {
        console.error('Error fetching all materials', err);

        if (err.response && err.response.message) {
          setError(err.response.message);
        }
      } finally {
        setIsMaterialLoading(false);
      }
    };

    fetchAllMaterials();
  }, [accessToken]);

  useEffect(() => {
    const fetchFavoriteMaterials = async () => {
      try {
        setIsFavoritesLoading(true);

        const res = await getFavoriteMaterials(accessToken);
        const favorites = res.data.map((item) => ({
          ...item,
          quantity: 0,
          isFavorite: true,
        }));

        setFavoriteMaterials(favorites);
        setInitialValues({ ...initialValues, orderItems: favorites });
      } catch (err) {
        console.error('Error fetching favorite materials', err);

        if (err.response && err.response.message) {
          setError(err.response.message);
        }
      } finally {
        setIsFavoritesLoading(false);
      }
    };

    fetchFavoriteMaterials();
  }, [accessToken]);

  useEffect(() => {
    const fetchOrder = async (orderUuid) => {
      try {
        setIsOrderLoading(true);

        const res = await getOrder(accessToken, orderUuid);

        if (res) {
          const orderDataResult = res.data;
          const favoritesId = Object.values(favoriteMaterials).map((material) => material.id);

          const materials = orderDataResult.materials.map((item) => {
            return {
              ...item,
              isFavorite: favoritesId.includes(item.id),
            };
          });

          setIsPartiallyEditable(orderDataResult.is_partially_editable);

          setInitialValues({
            orderItems: materials,
            pickupDate: dayjs(orderDataResult.when_to_pick_up),
            deliveryDate: dayjs(orderDataResult.when_to_deliver),
            boxCount: orderDataResult.box_count,
            comment: orderDataResult.comment,
          });
        } else {
          console.error('Order not found');

          setError('order_form.errors.order_not_found');
        }
      } catch (err) {
        console.error('Fetch Order error', err);

        setError(err.response.message);
      } finally {
        setIsOrderLoading(false);
      }
    };

    if (orderId && favoriteMaterials) {
      fetchOrder(orderId);
    } else {
      setInitialValues({ ...FORM_INITIAL_VALUES, orderItems: favoriteMaterials });

      setIsOrderLoading(false);
    }
  }, [accessToken, orderId, favoriteMaterials]);

  useEffect(() => {
    if (!orderId) {
      setInitialValues({ ...FORM_INITIAL_VALUES, orderItems: favoriteMaterials });

      setIsOrderLoading(false);
    }
  }, [orderId]);

  const validateForm = (values) => {
    const errors = {};

    if (Object.values(values.orderItems).filter((item) => item.quantity > 0).length === 0) {
      errors.orderItems = 'Musíte vybrat alespoň jeden materiál';
    }

    if (new Date(values.when_to_pick_up) >= new Date(values.when_to_deliver)) {
      errors.when_to_pick_up = 'Datum vyzvednutí musí být před datem doručení';
    }

    return errors;
  };

  const handleSubmit = async (values) => {
    setError(null);

    const formErrors = validateForm(values);

    if (Object.keys(formErrors).length > 0) {
      setError(Object.values(formErrors)[0]);

      return scrollToTop();
    }

    if (errorMessage !== null) {
      setError(errorMessage);
      setErrorMessage(null);

      return scrollToTop();
    }

    const orderItems = Object.values(values.orderItems).filter((item) => item.quantity > 0);

    setIsSubmitting(true);

    const orderData = {
      orderItems,
      pickupDate: dayjs(values.pickupDate).format('YYYY-MM-DD'),
      deliveryDate: dayjs(values.deliveryDate).format('YYYY-MM-DD'),
      boxCount: values.boxCount,
      comment: values.comment,
    };

    try {
      if (orderId) {
        await updateOrder(accessToken, orderId, orderData);
      } else {
        await createOrder(accessToken, orderData);
      }

      setSuccess(true);
    } catch (err) {
      console.error('Order create error', err);

      let formErrorMessage = '';

      if (
        err.response.data.message === 'The given data was invalid.' &&
        err.response.data.errors &&
        err.response.data.errors.length > 0
      ) {
        formErrorMessage = ': ' + err.response.data.errors[0];
      } else {
        formErrorMessage = ': ' + err.response.data.message;
      }

      if (formErrorMessage === undefined) {
        formErrorMessage = ': ' + err.toString();
      }

      setError(
        'Chyba při odeslání formuláře' +
          formErrorMessage +
          '. Zkontrolujte prosím formulář a zkuste to znovu.'
      );

      return scrollToTop();
    } finally {
      setIsSubmitting(false);
    }

    scrollToTop();

    setTimeout(() => {
      navigate('/orders');
    }, 2000);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <OrderFormikForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      accessToken={accessToken}
      allMaterials={allMaterials}
      defaultPickupDate={defaultPickupDate}
      defaultDeliveryDate={defaultDeliveryDate}
      isEditMode={!!orderId}
      isPartiallyEditable={isPartiallyEditable}
      success={success}
      error={error}
    />
  );
};

export default OrderFormHandler;
