import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import QuantityBox from './util/QuantityBox';

const AutocompleteRow = ({
  allMaterials,
  material,
  onQuantityChange,
  onRemoveRow,
  onItemChange,
  orderItems,
  index,
  isFocus = false,
  gridRowStyle,
}) => {
  const { t, i18n } = useTranslation();
  const [isQuantityFocus, setIsQuantityFocus] = useState(false);
  const options = [
    ...allMaterials,
    {
      id: material.id,
      title_cs: t('order_form.choose'),
      title_en: t('order_form.choose'),
      title_uk: t('order_form.choose'),
    },
  ];
  const materialTitle = material[`title_${i18n.language}`];

  const handleItemChange = (value) => {
    const newOrderItem = {
      ...orderItems[index],
      id: value ? value.id : material.id,
      title_cs: value ? value.title_cs : '',
      title_en: value ? value.title_en : '',
      title_uk: value ? value.title_uk : '',
    };

    onItemChange(newOrderItem, index);

    setIsQuantityFocus(true);
  };

  return (
    <Grid container spacing={0} sx={gridRowStyle} key={material.id}>
      <Grid container item spacing={0}>
        <Grid item xs={6}>
          <Autocomplete
            className="autocomplete-input"
            id="combo-box"
            size="small"
            value={material}
            disablePortal
            // filterSelectedOptions
            onChange={(event, newValue) => {
              handleItemChange(newValue);
            }}
            autoFocus={isFocus}
            options={options}
            getOptionLabel={(option) => option[`title_${i18n.language}`]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // filterOptions={(options, state) => {
            //   return options.filter((option) => { orderItems.includes(option.id) });
            // }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={`${option.id}_${option.title_cs}`}>
                  {option[`title_${i18n.language}`]}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  placeholder={t('order_form.choose')}
                  value={materialTitle || ''}
                  autoFocus={isFocus}
                  focused={isFocus}
                  {...params}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <QuantityBox
            onChange={(value) => onQuantityChange(value, index)}
            initialValue={material.quantity}
            minimumValue={0}
            id={`quantity-${material.id}`}
            isFocus={isQuantityFocus}
          />
        </Grid>
        <Grid item xs={2}>
          {!material.isFavorite && (
            <Button
              type="button"
              className="btn btn-link"
              onClick={() => onRemoveRow(index)}
              sx={{
                padding: 0,
                marginLeft: 4,
                marginTop: 1,
                width: { xs: '20px', sm: '20px' },
                minWidth: { xs: 0, sm: 0 },
              }}
            >
              <RemoveCircleIcon sx={{ color: 'red', margin: 0, padding: 0 }} />
              <span className="remove-row-button-label">{t('order_form.remove_material')}</span>
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AutocompleteRow;
