import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import AccountManagerList from '../components/AccountManagerList';
import OrderForm from '../components/order/OrderFormHandler';
import OrderList from '../components/order/OrderList';
import PasswordReset from '../components/auth/PasswordReset';
import LoginForm from '../components/auth/LoginForm';
import Logout from '../components/auth/Logout';
import UserAccount from '../components/user/UserAccount';
import UserList from '../components/user/UserList';
import { useAuth } from '../hooks/useAuth';

function Router() {
  const { user } = useAuth();
  const accessToken = user ? user.access_token : null;
  const isAccountManager = user ? user.is_account_manager : false;
  const isSupervisor = isAccountManager ? user.is_supervisor : false;

  if (!user) {
    return (
      <Routes>
        <Route index element={<LoginForm />} />
        <Route path="password-reset" element={<PasswordReset />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  if (isAccountManager) {
    return (
      <Routes>
        <Route index element={<Navigate to="/users" replace />} />
        <Route path="users" exact={true}>
          <Route
            index={true}
            element={
              <ProtectedRoute token={accessToken}>
                <UserList accessToken={accessToken} isSupervisor={isSupervisor} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/orders"
            element={
              <ProtectedRoute token={accessToken}>
                <OrderList accessToken={accessToken} isAccountManager={true} />
              </ProtectedRoute>
            }
          />
        </Route>
        {isSupervisor && (
          <Route path="account-managers" exact={true}>
            <Route
              index={true}
              element={
                <ProtectedRoute token={accessToken}>
                  <AccountManagerList accessToken={accessToken} />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/users"
              element={
                <ProtectedRoute token={accessToken}>
                  <UserList accessToken={accessToken} isSupervisor={true} />
                </ProtectedRoute>
              }
            />
          </Route>
        )}
        <Route
          path="user/account"
          element={
            <ProtectedRoute token={accessToken}>
              <UserAccount accessToken={accessToken} isAccountManager={true} />
            </ProtectedRoute>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route index element={<Navigate to="/orders" replace />} />
      <Route path="orders" exact={true}>
        <Route
          index={true}
          element={
            <ProtectedRoute token={accessToken}>
              <OrderList accessToken={accessToken} isAccountManager={false} />
            </ProtectedRoute>
          }
        />
        <Route
          path="create"
          element={
            <ProtectedRoute token={accessToken}>
              <OrderForm accessToken={accessToken} />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit/:uuid"
          element={
            <ProtectedRoute token={accessToken}>
              <OrderForm accessToken={accessToken} />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="user/account"
        element={
          <ProtectedRoute token={accessToken}>
            <UserAccount accessToken={accessToken} isAccountManager={false} />
          </ProtectedRoute>
        }
      />
      <Route path="logout" element={<Logout />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default Router;
