import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalView = ({ open, setOpen, headingTitle, content }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflow: 'scroll' }}
    >
      <Box sx={style}>
        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {headingTitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} />
        <Box>{content}</Box>
        <Box sx={{ paddingLeft: '40%' }}>
          <Button variant="contained" color="primary" onClick={handleClose} sx={{ marginTop: 2 }}>
            Zavřít
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalView;
