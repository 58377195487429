import React, { Suspense } from 'react';
import Layout from './components/layout/Layout';
import Loader from './components/util/Loader';
import { AuthProvider } from './hooks/useAuth';
import './App.css';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </Suspense>
  );
}

export default App;
